.form,
.form__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.form__container{
    width: 80%;
}
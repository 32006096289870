
.home__LinkHeader{
    font-size: 18px;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
}
.home ul{
    list-style-type: none;
}

.home li{
    display: flex;
    justify-content: space-around;
    padding: 0 20px 0 20px;
    margin-bottom: 10px;
}
.home li span{
    width: 100%;
}
.home li button{
    margin-right: 10px;
}